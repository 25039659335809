import { combineReducers } from 'redux';
import userReducer, { UserState } from './user';
import settingReducer, { SettingState } from './setting';
import adminReducer, { AdminState } from './admin';
import chatReducer, { ChatState } from '../slicer/chat';

export interface RootState {
  chat: ChatState;
  user: UserState;
  setting: SettingState;
  admin: AdminState;
}

const rootReducer = combineReducers({
  chat: chatReducer,
  user: userReducer,
  setting: settingReducer,
  admin: adminReducer,
});

export default rootReducer;
