import { ITheme, config } from 'react-styled-flexboxgrid';
// import { DefaultTheme } from 'styled-components';

const flexboxgrid: ITheme = {
  // Defaults
  gridSize: 12, // columns
  gutterWidth: 0.5, // rem
  outerMargin: 2, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 76, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 40, // em
    md: 48, // em
    lg: 64, // em
  },
};

config(flexboxgrid);

const mediaScreenBuilder = (target?: number, next?: number, reduction = 0.01) => {
  const minWidth = target;
  const maxWidth = next ? next - reduction : 0;
  return `@media only screen and (min-width: ${minWidth}em) ${maxWidth ? `and (max-width: ${maxWidth}em)` : ''}`;
};

export const defaultTheme = {
  colors: {
    background: {
      primary: '#EDEDED',
      secondary: '#FBFBFB',
      strong: '#FFFFFF',
      barely: '#AAAAAA',
    },
    text: {
      primary: '#333333',
      secondary: '#111111',
      strong: '#000000',
      barely: '#888888',
    },
    palette: {
      green: '#dcf8c6',
      red: '#F01818',
    },
    border: '#e3e3e3',
    // shadow: 'rgba(0, 0, 0, 0.2)',
    scrollbar: {
      thumb: '#00000022',
      track: '#ffffff14',
    },
    input: {
      background: '#EDEDED',
      backgroundDisabled: '#c9c9c9',
    },
  },
  variant: {
    blue: {
      background: '#007bff',
      text: '#ffffff',
    },
    grey: {
      background: '#868e96',
      text: '#ffffff',
    },
    green: {
      background: '#28a745',
      text: '#ffffff',
    },
    aqua: {
      background: '#17a2b8',
      text: '#ffffff',
    },
    yellow: {
      background: '#eea236',
      text: '#ffffff',
    },
    red: {
      background: '#dc3545',
      text: '#ffffff',
    },
    light: {
      background: '#f8f9fa',
      text: '#212529',
    },
    dark: {
      background: '#343a40',
      text: '#ffffff',
    },
    purple: {
      background: '#964dfa',
      text: '#ffffff',
    },
  },
  flexboxgrid,
  media: {
    xs: mediaScreenBuilder(flexboxgrid.breakpoints?.xs, flexboxgrid.breakpoints?.sm),
    sm: mediaScreenBuilder(flexboxgrid.breakpoints?.sm, flexboxgrid.breakpoints?.md),
    md: mediaScreenBuilder(flexboxgrid.breakpoints?.md, flexboxgrid.breakpoints?.lg),
    lg: mediaScreenBuilder(flexboxgrid.breakpoints?.lg),
  },
};

declare module 'styled-components' {
  export interface MainTheme {
    colors: {
      background: {
        primary: string;
        secondary: string;
        strong: string;
        barely: string;
      };
      text: {
        primary: string;
        secondary: string;
        strong: string;
        barely: string;
      };
      palette: {
        [key: string]: string;
      };
      border: string;
      // shadow: string;
      scrollbar: {
        thumb: string;
        track: string;
      };
      input: {
        background: string;
        backgroundDisabled: string;
      };
    };
  }

  export interface DefaultTheme extends MainTheme {
    variant: typeof defaultTheme.variant;
    flexboxgrid: typeof flexboxgrid;
    media: typeof defaultTheme.media;
  }
}
