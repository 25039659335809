import React from 'react';
import CircleLogo from '../../../assets/icon/circle-logo.png';
import { Wrapper } from './styles';

const MainLogo: React.FC = () => {
  return (
    <Wrapper>
      <img src={CircleLogo} alt="" />
    </Wrapper>
  );
};

export default MainLogo;
