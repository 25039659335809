import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from '../reducer';
import { initialState as adminIntialState } from '../reducer/admin';
import { initialState as settingIntialState } from '../reducer/setting';

const store = createStore(
  rootReducer,
  load({
    states: ['setting', 'admin'],
    preloadedState: {
      admin: JSON.parse(JSON.stringify(adminIntialState)),
      setting: JSON.parse(JSON.stringify(settingIntialState)),
    },
  }),
  composeWithDevTools(
    applyMiddleware(
      save({
        states: ['setting', 'admin'],
      }),
      thunk,
    ),
  ),
);

export default store;
